@font-face {
  font-family: MetaPro;
  src: url("fonts/MetaPro-Regular.woff2");
  format: ("woff2");
  font-display: swap;
}

body {
  min-height: 100%;
  background-color: #F2F3F5;
  font-family: "MetaPro";
}

.main {
  background-color: #ffffff;
}

.footer {
  background-color: #F2F3F5;
}
