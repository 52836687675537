.loader-spinner-base {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1500;
}

.loader-spinner-overlay {
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .75;
}

.loader-spinner-container {
    display: inline-block;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -48px;
    text-align: center;
}

.loader-spinner-circle-container {
    display: inline-block;
    width: 100%;
    height: 96px;
    position: relative;
}

.loader-spinner-circle-container>div {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    background: #0091dc;
    border-radius: 100%;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
}

.loader-spinner-circle-outer {
    width: 96px;
    height: 96px;
    margin-top: -48px;
    margin-left: -48px;
    z-index: 1;
    opacity: .15;
    animation-name: pulse-outer;
    animation-duration: 1s;
}

.loader-spinner-circle-center {
    width: 64px;
    height: 64px;
    margin-top: -32px;
    margin-left: -32px;
    z-index: 2;
    opacity: .3;
    animation-name: pulse-center;
    animation-duration: 100ms;
}

.loader-spinner-circle-inner {
    width: 32px;
    height: 32px;
    margin-top: -16px;
    margin-left: -16px;
    z-index: 3;
    opacity: 1;
    animation-name: pulse-inner;
    animation-duration: 200ms;
}

.loader-spinner-text {
    display: inline-block;
    max-width: 50%;
    font-size: 16px;
    text-align: center;
    color: #fff;
}

@keyframes pulse-outer {
    from {
        transform: scale(1)
    }

    to {
        transform: scale(0.5)
    }
}